<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 report p-3">
        <div slot="with-padding">
          <div class="col-md-12">
          <h3 class="ml-2"><i class="icofont icofont-pie-chart" style="color:#fff;"></i> Quick Stats</h3>
          <hr style="border-top-color: #222;border-bottom-color: #616161;margin-top: 10px !important;margin-bottom: 20px;">
          <b-row>
            <b-col>
              <!-- <h5>You are currently using 1 of your 50 monitors.</h5> -->
            </b-col>
          </b-row>
          <b-row>
            <div class="col c_card">
              <router-link class="text-white" :to="{ path: '/app/monitor?type=up' }">
              <div class="c_header text-center">
                <span>
                  <span class="dot-green">●</span>
                  UP MONITORS</span>
              </div>
              <div class="col-md-12 c_body text-center">
                <div class="item html">
                    <h2>{{ monitor_report.up }}</h2>
                    <svg
                      width="160"
                      height="160"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <circle
                          v-if="monitor_report.up > 0"
                          class="circle_animation"
                          r="69.85699"
                          cy="81"
                          cx="81"
                          stroke-width="8"
                          stroke="#6fdb6f"
                          fill="none"
                        />
                        <circle
                          v-else
                          class="circle_animation"
                          r="69.85699"
                          cy="81"
                          cx="81"
                          stroke-width="8"
                          stroke="#bdbdbd"
                          fill="none"
                        />
                      </g>
                    </svg>
                  </div>
                  <div class="col-md-12">
                    <span class="link_txt text-white">SHOW "UP" MONITORS</span>
                  </div>
              </div>
            </router-link>
            </div>
            <div class="col c_card">
              <router-link class="text-white" :to="{ path: '/app/monitor?type=down' }">
              <div class="c_header text-center">
                <span>
                  <span class="dot-red">●</span>
                  DOWN MONITORS</span>
              </div>
              <div class="col-md-12 c_body text-center">
                <div class="item html">
                    <h2>{{ monitor_report.down }}</h2>
                    <svg
                      width="160"
                      height="160"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <circle
                          v-if="monitor_report.down > 0"
                          class="circle_animation"
                          r="69.85699"
                          cy="81"
                          cx="81"
                          stroke-width="8"
                          stroke="red"
                          fill="none"
                        />
                        <circle
                          v-else
                          class="circle_animation"
                          r="69.85699"
                          cy="81"
                          cx="81"
                          stroke-width="8"
                          stroke="#bdbdbd"
                          fill="none"
                        />
                      </g>
                    </svg>
                  </div>
                  <div class="col-md-12">
                    <span class="link_txt text-white">SHOW "DOWN" MONITORS</span>
                  </div>
              </div>
            </router-link>
            </div>
            <div class="col c_card">
              <router-link class="text-white" :to="{ path: '/app/monitor?type=pause' }">
              <div class="c_header text-center">
                <span>
                  <span class="dot-black">●</span>
                  PAUSED MONITORS</span>
              </div>
              <div class="col-md-12 c_body text-center">
                <div class="item html">
                    <h2>{{ monitor_report.pause }}</h2>
                    <svg
                      width="160"
                      height="160"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <circle
                          v-if="monitor_report.pause > 0"
                          class="circle_animation"
                          r="69.85699"
                          cy="81"
                          cx="81"
                          stroke-width="8"
                          stroke="#fff"
                          fill="none"
                        />
                        <circle
                          v-else
                          class="circle_animation"
                          r="69.85699"
                          cy="81"
                          cx="81"
                          stroke-width="8"
                          stroke="#bdbdbd"
                          fill="none"
                        />
                      </g>
                    </svg>
                  </div>
                  <div class="col-md-12">
                    <span class="link_txt text-white">SHOW "PAUSED" MONITORS</span>
                  </div>
              </div>
            </router-link>
            </div>
          </b-row>
        </div>
          <!-- <div class="col-md-4">

          </div> -->
          </div>
      </div>
    </div>
  </div>
</template>


<script>
import DashboardController from "../../services/dashboardController";
export default {
  name: "report",
  components: { },
  data() {
    return {
      monitor_report: {up: 0,down:0,pause:0},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let response = await DashboardController.getMonitorReport();
      if (response.message) {
        if(response.data.up){this.monitor_report.up = response.data.up;}
        if(response.data.down){this.monitor_report.down = response.data.down;}
        if(response.data.pause){this.monitor_report.pause = response.data.pause;}
      }
    },
  },
};
</script>


<style>
.c_card{
  padding: 10px;
  margin: 10px;
  background: #323538;
  border-radius: 8px;
  box-shadow: 0px 0px 3px 2px;
}
.c_header{
  padding: 5px;
  margin: 5px;
  border-bottom: 2px solid black;
  box-shadow: 0px 0px 7px 0px;
}
.dot-green {
  color: green;
  font-size: 25px;
}
.dot-red {
  color: red;
  font-size: 25px;
}
.dot-black {
  color: black;
  font-size: 25px;
}
.report{
  margin-bottom: 30px;
    border: 0px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 15px 10px #f7f7f7;
    box-shadow: 0 0 15px 10px #f7f7f7;
    background: #373b3e;
    color: #eeeeee;
  /* background: #373b3e;
  color: #eeeeee;
  border-radius: 10px;
  margin: 5px;
  padding: 5px; */
}
.item {
  position: relative;
  /* float: left; */
}

.item h2 {
  text-align: center;
  position: absolute;
  line-height: 160px;
  width: 100%;
}

/* svg {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
} */

.circle_animation {
  stroke-dasharray: 550; /* this value is the pixel circumference of the circle */
  stroke-dashoffset: 440;
}

.html .circle_animation {
  -webkit-animation: html 1s ease-out forwards;
  animation: html 1s ease-out forwards;
}

.css .circle_animation {
  -webkit-animation: css 1s ease-out forwards;
  animation: css 1s ease-out forwards;
}

@-webkit-keyframes html {
  to {
    stroke-dashoffset: 80; /* 50% would be 220 (half the initial value specified above) */
  }
}

@keyframes html {
  to {
    stroke-dashoffset: 80;
  }
}

@-webkit-keyframes css {
  to {
    stroke-dashoffset: 160;
  }
}

@keyframes css {
  to {
    stroke-dashoffset: 160;
  }
}
</style>