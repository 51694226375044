<template>
    <div>
        <Breadcrumbs main="" title="Dashboard" />
        <div class="row">
            <div class="col">
                <report-vue></report-vue>
            </div>
        </div> 
        <div class="row">
            <div class="col">
                <div class="row mt-3 justify-content-center">
                    <div class="card col-md-9 text-left p-4 report" style="box-shadow: 1px 3px 20px 1px #05286b;">
                            <div class="row">
                            <div class="col">
                                <h2 class="font-weight-bold">Overall Uptime</h2>
                            </div>
                            <div class="col-md-3 col-12">
                                <b-form-select name="type" size="sm" v-model="monitor" :options="monitor_options" @input="getUptime()" required>
                                </b-form-select>
                            </div>
                            </div>
                            <div class="row p-5">
                            <div class="col text-center" style="border-right: 1px solid">
                                <h1 class="font-weight-bold">
                                {{ overalluptime.d7.uptime.toFixed(3) }}%
                                </h1>
                                <span class="">Last 7 days</span>
                            </div>
                            <div class="col text-center" style="border-right: 1px solid">
                                <h1 class="font-weight-bold">
                                {{ overalluptime.d30.uptime.toFixed(3) }}%
                                </h1>
                                <span class="">Last 30 days</span>
                            </div>
                            <div class="col text-center">
                                <h1 class="font-weight-bold">
                                {{ overalluptime.d90.uptime.toFixed(3) }}%
                                </h1>
                                <span class="">Last 90 days</span>
                            </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div><hr>
        <div class="row">
            <div class="col-md-6 col-12">
                <incedent-vue :monitor_data="monitor_opt"></incedent-vue>
            </div>
            <div class="col-md-6 col-12">
                <event-vue :monitor_data="monitor_opt"></event-vue>
            </div>
        </div>
        
        
    </div>
</template>


<script>
import IncedentVue from "./Incedent.vue";
import EventVue from "./Events.vue";
import ReportVue from "./Report.vue";
import MonitorsController from "../../services/monitorController";
import DashboardController from "../../services/dashboardController";
export default{
    name:'dashboard',
    components: { IncedentVue, EventVue, ReportVue},
    data(){
        return {
            monitor_opt:{},
            monitor_options : [{ value: null, text: "All Monitors Uptime"}] ,
            overalluptime:{d7: { uptime: 0 },d30: { uptime: 0 },d90: { uptime: 0 }},
            monitor:null
        }
    },
    mounted(){
        this.getMonitors();
        this.getUptime()
    },
    methods:{
    async getMonitors() {
      let response = await MonitorsController.getMonitors();
      if (response.result) {
        response.data.map((t) => {
        //   this.monitor_opt.push({ value: t._id, text: t.name, type:t.type,isDown:t.isDown });
          this.monitor_options.push({ value: t._id, text: t.name, type:t.type,isDown:t.isDown });
        });
      }
    },
    async getUptime(){
        var response 
        if(this.monitor){
            let payload = '?mid='+this.monitor
            response = await DashboardController.getUptimeByMid(payload)
        }
        else{
          response = await DashboardController.getUptime()
        }
        if(response.result){
            this.overalluptime = response.data
        }
    }
    }
}
</script>